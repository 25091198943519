import { type SVGProps } from 'react'
import { cn } from '#app/utils/tailwind-merge.ts'
import { type IconName } from './icon/name.js'
import href from './icon/sprite.svg'

export type IconProps = SVGProps<SVGSVGElement> & { name: IconName; label?: string; size?: 'font' | 'sm' | 'md' | 'lg'; children?: never }

/**
 * Renders an SVG icon with optimal performance.
 */
const Icon = ({ name, label, size = 'font', className, ...props }: IconProps) => {
	return (
		<>
			<svg
				aria-hidden="true"
				focusable="false" // See: https://allyjs.io/tutorials/focusing-in-svg.html#making-svg-elements-focusable
				{...props}
				className={cn('icon', size, className)}
			>
				<use href={`${href}#${name}`} />
			</svg>
			{label ?
				<span className="sr-only">{label}</span>
			:	null}
		</>
	)
}
export { Icon, IconName, href }
